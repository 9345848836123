<template>
  <component :is="layout">
    <prompt :active="isModalVisible" @close="isModalVisible = false">
      <h3 slot="header">
        Send an invitation
      </h3>
      <div slot="content">
        <invite-form
          @close="isModalVisible = false"
          @inviteCreated="getInvites"
        />
      </div>
    </prompt>

    <prompt :active="isUserModalVisible" @close="isUserModalVisible = false">
      <h3 slot="header">
        Update team member
      </h3>
      <div slot="content">
        <user-form
          :user="userToEdit"
          :can-change-role="allowRoleChange"
          v-on:close="isUserModalVisible = false"
          @deleteItem="removeUser"
        />
      </div>
    </prompt>

    <div class="row fadeInUp" role="main">
      <div class="col-12 col-md-3">
        <OrganizationNav />
      </div>
      <div class="col-12 col-md-9">
        <div class="card border-0 shadow-sm mb-6">
          <div class="card-header">
            <h4>Team Members ({{ users.length }})</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div v-for="(user, index) in users" :key="index" class="col-md-6">
                <UserCard
                  :user="user"
                  :index="index"
                  :is-owner="getOrganization.owner === user.id"
                  :show-delete="false"
                  @editUser="editUser"
                  @deleteItem="removeUser"
                />
              </div>
            </div>
          </div>
          <div v-if="hasPermission('invite-users')" class="card-footer">
            <button
              class="btn btn-secondary float-right"
              @click="isModalVisible = true"
            >
              Invite users
            </button>
          </div>
        </div>
        <div v-if="getUser.role != 'contributor'">
          <h5>Pending invites ({{ Object.keys(invites.data).length }})</h5>
          <div class="card border-0 shadow-sm">
            <div class="card-body">
              <div class="row">
                <div
                  v-for="(invite, index) in invites.data"
                  :key="index"
                  class="col-md-6"
                >
                  <UserCard
                    :user="invite"
                    :index="index"
                    :is-owner="false"
                    :show-edit="false"
                    :show-delete="true"
                    @deleteItem="removeInvite"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import { mapGetters } from "vuex";
import jsonAPI from "@/api";
import Default from "@/layouts/default.vue";
import Permission from "@/utils/permission";
import Prompt from "@/components/UI/Prompt.vue";
import InviteForm from "@/components/Form/InviteForm.vue";
import UserForm from "@/components/Form/UserForm.vue";
import UserCard from "@/components/Account/UserCard.vue";
import OrganizationNav from "@/components/Nav/OrganizationNav.vue";

export default {
  components: {
    Default,
    Prompt,
    InviteForm,
    UserForm,
    UserCard,
    OrganizationNav,
  },
  extends: Permission,
  beforeRouteEnter(to, from, next) {
    jsonAPI.get("organization/current").then((response) => {
      next((vm) => {
        vm.users = response.data.users;
      });
    });
  },
  data: function() {
    return {
      layout: "Default",
      isModalVisible: false,
      isUserModalVisible: false,
      loading: false,
      users: [],
      invites: {
        data: [],
      },
      userToEdit: {},
    };
  },
  computed: {
    ...mapGetters(["isActive", "getUser", "getOrganization"]),
    allowRoleChange() {
      return this.getUser.role === "admin";
    },
  },
  mounted() {
    this.getInvites();
  },
  methods: {
    editUser(id) {
      this.userToEdit = this.users.filter((item) => {
        return item.id === id;
      })[0];
      this.isUserModalVisible = true;
    },
    getInvites() {
      jsonAPI.get("/invite").then((response) => {
        this.invites = response.data;
      });
    },
    removeUser(_id) {
      this.users = this.users.filter((obj) => {
        return obj.id !== _id;
      });
    },
    removeInvite(_id) {
      this.invites.data = this.invites.data.filter((obj) => {
        return obj.id !== _id;
      });
    },
  },
};
</script>
