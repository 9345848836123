<template>
  <div class="card mb-6">
    <div class="card-body">
      <div class="media w-100">
        <div
          class="avatar-initials mr-4"
          :style="[{ background: getRandomColor(index) }]"
        >
          {{ getInitials(user.name) }}
        </div>
        <div class="media-body d-flex">
          <div class="flex-grow">
            <p class="font-weight-bold text-dark mb-0">
              {{ user.name }}
            </p>
            <p class="text-muted mb-0">
              {{ user.title }}
            </p>
          </div>
          <div class="d-flex flex-column justify-content-end">
            <button v-if="isOwner" class="btn btn-sm btn-secondary disabled">
              Admin (owner)
            </button>
            <button
              v-if="showEdit && hasPermission('edit-users') && !isOwner"
              class="btn btn-sm btn-outline-secondary mb-3"
              @click="editUser"
            >
              Edit
            </button>
            <DeleteIconButton
              v-if="showDelete && hasPermission('delete-users') && !isOwner"
              v-tooltip.left="'Remove'"
              class="cursor-pointer"
              :uri="`invite/${user.id}`"
              :name="user.name"
              @deleted="userDeleted"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Permission from "@/utils/permission";
import DeleteIconButton from "@/components/Form/DeleteIconButton.vue";

export default {
  name: "UserCard",
  components: {
    DeleteIconButton,
  },
  extends: Permission,
  props: {
    user: {
      type: Object,
      default: () => {
        return {
          id: "",
          name: "",
          title: "",
        };
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    isOwner: {
      type: Boolean,
      default: false,
    },
    showEdit: {
      type: Boolean,
      default: true,
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      colors: [
        "#1fbc9c",
        "#2ecc70",
        "#3398db",
        "#a463bf",
        "#3d556e",
        "#f2c511",
        "#e84b3c",
        "#2ecc70",
        "#3398db",
        "#a463bf",
        "#3d556e",
        "#f2c511",
        "#e84b3c",
      ],
      deleteButton: false,
    };
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  methods: {
    getRandomColor: function(index) {
      return this.colors[index];
    },
    getInitials: function(name) {
      var parts = name.split(" ");
      var initials = "";
      for (var i = 0; i < parts.length; i++) {
        if (parts[i].length > 0 && parts[i] !== "") {
          initials += parts[i][0];
        }
      }
      return initials.toUpperCase();
    },
    editUser() {
      this.$emit("editUser", this.user.id);
    },
    close() {
      this.deleteButton = false;
    },
    deleteItem() {
      this.deleteButton = true;
    },
    userDeleted() {
      this.$emit("deleteItem", this.user.id);
    },
  },
};
</script>

<style scoped>
.flex-grow {
  flex-grow: 1;
}
</style>
